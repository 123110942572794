<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="bet365-gamefram" scrolling="no" frameborder="0"
                    src="https://b4.nutv365.com/ios/?vn=11"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">베이커</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="b_s_match_box">
              <div class="team_name">
                <div class="home"><span>{{ spGame.homeTeamName }}</span></div>
                <div class="vs"><span>VS</span></div>
                <div class="away"><span>{{ spGame.awayTeamName }}</span></div>
              </div>
              <div class="mg">
                <div class="mg_btn b3"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2 && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2, spGame.id,1, spGame.homeTeamOdds, spConfig, '승')">
                  <div class="rs">
                    <span class="n text_color01">승</span>
                    <span class="b">{{ spGame.homeTeamOdds }}</span>
                  </div>
                </div>
                <div class="mg_btn b3"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2 && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2, spGame.id,3,  spGame.awayTeamOdds, spConfig, '패')">
                  <div class="rs">
                    <span class="n text_color02">패</span>
                    <span class="b">{{ spGame.awayTeamOdds }}</span>
                  </div>
                </div>
              </div>
              <div class="mg">
                <div class="mg_btn b2"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER,souGame.id,1, souGame.homeTeamOdds, souConfig, '오버')">
                  <div class="rs">
                    <span class="n text_color01">오버({{ souGame.overunderVal }})</span>
                    <span class="b">{{ souGame.homeTeamOdds }}</span>
                  </div>
                </div>
                <div class="mg_btn b2"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER,souGame.id,3,  souGame.awayTeamOdds, souConfig, '언더')">
                  <div class="rs">
                    <span class="n text_color02">언더({{ souGame.overunderVal }})</span>
                    <span class="b">{{ souGame.awayTeamOdds }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            베이커
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";
import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";

import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "Bet365BasketBallBakerLeague",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp
  },

  data() {
    return {
      kind: leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER,
    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data
          //배당설정
          this.spConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_1x2)
          })

          this.souConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_BASKETBALL_BAKER_OVERUNDER)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_BET365);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
}
</script>


<style scoped>
@media screen and (max-width: 1024px) {
  .mini_game .m2 {
    --margin-top: 180px;
  }

  .mini_game .mini_fram {
    min-height: 210px !important;
  }
}
</style>